import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './simple-card.component.html',
  styleUrl: './simple-card.component.scss'
})
export class SimpleCardComponent {
  @Input() extraTwClasses: string = '';
  /**
   * Main title for card
   *
   * @type {string}
   */
  @Input() cardTitle: string = '';

  /**
   * Subtext for card
   *
   * @type {string}
   */
  @Input() description: string = '';

  /**
   * Icon path
   *
   * @type {string}
   */
  @Input() cardIcon: string = '';

  /**
   * URL / Route
   *
   * @type {string}
   */
  @Input() href: string = '';
}
