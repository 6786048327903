import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EdsButtonComponent } from '@electronds/ng-components-duke';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [EdsButtonComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  SIGNUP_LINK = 'https://p-auth.duke-energy.com/my-account/registration-r5';
  redirectUrl = '/home';

  constructor(
    private _router: Router,
    private _googleService: GoogleAnalyticsService,
  ) {
    
  }

  ngOnInit(): void {
    this._googleService.sendVPV('na/load/na/sign-in-options');
  }

  onClickSignIn(): void {
    this._router.navigate(['sign-in']);
  }

   returnToDukeEnergy(): void {
    // window.open('https://www.duke-energy.com/info/unindexed/my-energy-bill-plus', '_self');
    this._router.navigate(['home']);
  }
}