<div id='map'></div>
<form [formGroup]="addressForm">

  <!-- Street address where user can type their queries -->
<eds-input #streetAddress placeholder="Street Address" [control]="streetAddressControl" (inputChange)="searchPrediction($event)"></eds-input>

<!-- Predictions drop-down showing query results for user to select their address -->
<section *ngIf="this.searchPredictions.length > 0" class="">
  <ul class="px-12 pb-24" role="list">
      <li *ngFor="let prediction of searchPredictions; let i = index" class="border-solid border border-gray-light" role="listitem">
          <button class="btn btn-flat-reversed cursor-pointer block text-left h-fit w-full p-16 hover:bg-teal-lighter focus:bg-teal-lighter"
                  (click)="searchItemSelected(prediction)">
          <div class="search-panel-item-link mb-6 flex">
             
              <img class="search-panel-item-link-leftIcon mr-4"
                   src="assets/icons/location.svg"
                   alt="location"/>
                <span>
                  <p class="search-panel-item-link-text">
                    {{ prediction.structured_formatting.main_text }}
                </p>
                <p class="search-panel-item-address">
                  {{ prediction.structured_formatting.secondary_text }}
              </p>
                </span>
             
          </div>
   
      </button>
    </li>
  </ul>
</section>

<!-- Remaining address fields that will be auto populated by the selection above or modified manually by the user -->
<eds-input placeholder="Apartment, Suite (Optional)" [control]="apartmentSuiteControl"></eds-input>
<eds-input placeholder="City" [control]="cityControl"></eds-input>
<div class="md:flex gap-12">
    <div class="md:w-[300px] mb-16">
      <eds-select helperText="" label="State" [options]="statesList" required="true" (selectedOptionChanged)="stateSelectionChanged($event)" [selectedOptionId]="selectedStateOption.id"></eds-select>
    </div>
    <div class="md:w-[290px]">
      <eds-input placeholder="ZIP Code" [control]="zipControl"></eds-input>
    </div>
  </div>
</form>