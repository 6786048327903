<div class="px-20 md:px-80 md:mx-64 ">
  <h1 class="text-center text-xl md:mt-48 md:mb-48 md:block">
    Looks like this email address is already associated with a Duke Energy
    profile, do you want to use this profile and sync your information?
  </h1>
</div>

<div class="mx-20 md:mx-0">
<!--Yes button-->
<div
  class="container-lg relative flex md:p-16 p-20 rounded-md border border-gray bg-white mt-32 mb-20 hover:bg-teal-lighter"
>
<img src="assets/icons/check-duotone.svg" alt="duo-check" class="icon-32 mr-10 md:mr-0"/>
  <div class="flex-grow flex justify-center">
    
    <span (click)="onTransferAnswerClick('YES')" class="p-0">
      <a
        class="text-lg text-teal-darker before:absolute before:-inset-px before:rounded-md before:shadow-sm before:opacity-0 sm:hover:before:opacity-100 before:transition-opacity before:duration-300 before:ease-in-out"
      >
        {{ yesLabel }}
      </a>
    </span>
  </div>

  <div class="flex flex-shrink-0 items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 14"
      width="20px"
      height="20px"
    >
      <path
        d="M8.69 6.32l-.237-.22-.02-.02-.016-.01L2.023.278A1.1 1.1 0 0 0 1.285 0a1.1 1.1 0 0 0-.738.277L.31.49a.9.9 0 0 0 0 1.36L5.99 7 .31 12.15a.9.9 0 0 0 0 1.36l.237.213a1.1 1.1 0 0 0 .738.277 1.1 1.1 0 0 0 .738-.277l6.4-5.8.03-.023.237-.214a.9.9 0 0 0 0-1.362z"
        fill="#067799"
      />
    </svg>
  </div>
</div>
</div>

<!--No button-->
<div class="mx-20 md:mx-0">
<div
  class="container-lg relative flex p-16 rounded-md border border-gray bg-white hover:bg-teal-lighter"
>
<img src="assets/icons/x-duotone.svg" alt="x-duotone" class="icon-32 mr-10 md:mr-0"/>
  <div class="flex-grow flex justify-center">
    <span (click)="onTransferAnswerClick('NO')" class="pt-2">
      <a
        class="text-lg text-teal-darker before:absolute before:-inset-px before:rounded-md before:shadow-sm before:opacity-0 sm:hover:before:opacity-100 before:transition-opacity before:duration-300 before:ease-in-out"
      >
        {{ noLabel }}
      </a>
    </span>
  </div>

  <div class="flex flex-shrink-0 ml-16 items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 14"
      width="20px"
      height="20px"
    >
      <path
        d="M8.69 6.32l-.237-.22-.02-.02-.016-.01L2.023.278A1.1 1.1 0 0 0 1.285 0a1.1 1.1 0 0 0-.738.277L.31.49a.9.9 0 0 0 0 1.36L5.99 7 .31 12.15a.9.9 0 0 0 0 1.36l.237.213a1.1 1.1 0 0 0 .738.277 1.1 1.1 0 0 0 .738-.277l6.4-5.8.03-.023.237-.214a.9.9 0 0 0 0-1.362z"
        fill="#067799"
      />
    </svg>
  </div>
</div>
</div>

<!--Previous Button-->
<div class="container-md flex justify-center mt-20">
  <eds-button
    (buttonClick)="navigateTo()"
    text="Previous"
    type="secondary"
    extraTwClasses="p-32"
  ></eds-button>
</div>

<div class="container-md text-center md:mt-32 mt-36 p-12 md:p-0 mb-10">
  <p>
    By clicking 'yes' you provide consent to using your DE and DE1 information
    together under one profile. This is an example of consent language.
  </p>
</div>
