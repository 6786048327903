import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DeFooterModule } from '@de-electron/electron-angular-components';
import { provideNgxMask } from 'ngx-mask';

import { DeFooterModule, DeHeaderModule, DeLoaderModule, DeStepperModule } from '@de-electron/electron-angular-components';

import {
  EdsButtonComponent,
  EdsModalComponent,
  EdsButtonGroupComponent,
  EdsCheckboxComponent,
  EdsDrawerComponent,
  EdsEmailInputComponent,
  EdsInputComponent,
  EdsPasswordInputComponent,
  EdsPhoneInputComponent,
  EdsSelectComponent,
  EdsSignInComponent,
  EdsSimpleCardComponent,
  EdsSvgComponent,
  EdsToastComponent,
  EdsSiteFooterComponent,
  EdsSiteHeaderComponent,
  EdsHorizontalDividerComponent,
  EdsRadioComponent,
  EdsRadioGroupComponent,
  EdsTooltipComponent
} from '@electronds/ng-components-duke';
import { DisclaimerComponent } from '../disclaimer-component/disclaimer.component';
import { FaqComponent } from '../faq/faq.component';
import { SimpleCardComponent } from '../pages/simple-card/simple-card.component';
import { RouterModule } from '@angular/router';

const oldElectron = [
  DeFooterModule,
  DeHeaderModule,
  DeLoaderModule,
  DeStepperModule
];

const electronComponents = [
  EdsButtonComponent,
  EdsModalComponent,
  EdsButtonGroupComponent,
  EdsCheckboxComponent,
  EdsDrawerComponent,
  EdsInputComponent,
  EdsEmailInputComponent,
  EdsPhoneInputComponent,
  EdsSelectComponent,
  EdsSiteHeaderComponent,
  EdsPasswordInputComponent,
  EdsRadioComponent,
  EdsRadioGroupComponent,
  EdsSignInComponent,
  EdsSvgComponent,
  EdsSimpleCardComponent,
  EdsToastComponent,
  EdsSiteFooterComponent,
  EdsHorizontalDividerComponent,
  EdsTooltipComponent
];

const angular = [CommonModule, FormsModule, ReactiveFormsModule];
const sharedComponents = [DisclaimerComponent, FaqComponent, SimpleCardComponent];

@NgModule({
  declarations: [],
  imports: [...angular, ...electronComponents, oldElectron, sharedComponents, RouterModule],
  exports: [...angular, ...electronComponents, oldElectron, sharedComponents],
  providers: [provideNgxMask()]
})
export class SharedModule {}