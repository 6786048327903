<div class="flex flex-col m-0 p-0 h-screen min-w-[370px]">
  <app-one-header
    [actionButtons]="headerActionButtons"
    [currentNavGroup]="[0]"
    [navDrawerBody]="headerNavDrawerBody"
    [navGroups]="headerNavGroups"
  ></app-one-header>

  <!-- TODO: move below into a component -->
  <!--Hamburger Menu Body-->
  <ng-template #headerNavDrawerBody>
    <div class="container-lg">
      <button
        class="btn btn-xs btn-flat text-current hover:text-current border-b-2"
        type="button"
        [attr.aria-expanded]="showNav ? false : true"
        (click)="handleHeaderClicked()"
      >
        <span
          class="top-2 font-bold {{
            showNav ? 'border-l-4 border-teal-dark rounded-sm font-bold' : ''
          }}"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            class="icon-24 inline-flex {{showNav ? 'ml-10' : 'ml-0'}}"
            
          >
            <path
            fill="#00789E"
              d="M56.712,11.851c-.218-.829-.943-1.425-1.798-1.479C40.673,9.412,33.757,.85,33.693,.766,33.312,.281,32.731,0,32.115,0h-.224c-.614,0-1.194,.283-1.574,.766-.07,.087-6.982,8.649-21.23,9.609-.855,.055-1.578,.651-1.796,1.479-.306,1.115-6.954,27.616,23.354,51.714,.355,.28,.794,.432,1.247,.432h.224c.451,0,.889-.153,1.243-.432C63.665,39.47,57.011,12.969,56.712,11.855"
            />
          </svg>
          <div class="inline-flex sm:ml-10 md:ml-16 ml-18">
             {{ title }}
          </div>
         
        </span>
        <div>
          <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          fill="currentColor"
          class="icon-14 inline-flex relative {{showNav ? '-rotate-90 ml-192' : 'ml-192 left-4 sm:ml-128'}}"
        >
          <path
            d="M16.995,6.594c-1.44-1.634-1.303-4.12,.308-5.586,1.59-1.442,4.049-1.323,5.491,.268,.013,.015,.026,.029,.039,.044l24.169,27.308c1.304,1.479,1.332,3.688,.067,5.199l-24.166,28.776c-1.376,1.649-3.828,1.87-5.477,.493-.015-.012-.029-.025-.044-.037-1.65-1.424-1.851-3.908-.451-5.578l21.957-26.149L16.995,6.594Z"
          />
        </svg>
        </div>
      </button>
      <div class="border-b-2 border-gray-light mt-10"></div>
      <!--dropdown content-->
      <div
        class="grid relative transition-all {{
          showNav
            ? 'grid-rows-1fr duration-300 ease-out'
            : 'grid-rows-0fr duration-150 ease-in'
        }}"
        aria-hidden="true"
      >
        <div
          class="overflow-hidden transition-opacity opacity-100 duration-300 delay-75 ease-out"
        >
          <div class="mt-10 opacity-100 duration-300 delay-75 ease-out">
            <ul class="mt-10">
              <li>
                <a href="tier?plan=home-wiring-repair-plans" class=""
                  >Home Wiring Repair
                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  fill="currentColor"
                  class="icon-12 inline-flex relative left-144 sm:left-112 sm:ml-96 md:ml-112 ml-24"
                >
                  <path
                    d="M16.995,6.594c-1.44-1.634-1.303-4.12,.308-5.586,1.59-1.442,4.049-1.323,5.491,.268,.013,.015,.026,.029,.039,.044l24.169,27.308c1.304,1.479,1.332,3.688,.067,5.199l-24.166,28.776c-1.376,1.649-3.828,1.87-5.477,.493-.015-.012-.029-.025-.044-.037-1.65-1.424-1.851-3.908-.451-5.578l21.957-26.149L16.995,6.594Z"
                  />
                </svg> 
                  </a>
                
              </li>
              <li>
                <a href="tier?plan=surge-plans" class=""
                  >Surge Coverage & Grounding
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  fill="currentColor"
                  class="icon-12 inline-flex relative left-96 sm:left-112 sm:ml-144 md:ml-112 ml-8"
                >
                  <path
                    d="M16.995,6.594c-1.44-1.634-1.303-4.12,.308-5.586,1.59-1.442,4.049-1.323,5.491,.268,.013,.015,.026,.029,.039,.044l24.169,27.308c1.304,1.479,1.332,3.688,.067,5.199l-24.166,28.776c-1.376,1.649-3.828,1.87-5.477,.493-.015-.012-.029-.025-.044-.037-1.65-1.424-1.851-3.908-.451-5.578l21.957-26.149L16.995,6.594Z"
                  />
                </svg> 
                  </a>
               
              </li>
              <li>
                <a href="tier?plan=water-heater-repair-plans" class=""
                  >Water Heater Repair
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  fill="currentColor"
                  class="icon-12 inline-flex relative left-144 sm:left-112 sm:ml-144 md:ml-112 ml-24"
                >
                  <path
                    d="M16.995,6.594c-1.44-1.634-1.303-4.12,.308-5.586,1.59-1.442,4.049-1.323,5.491,.268,.013,.015,.026,.029,.039,.044l24.169,27.308c1.304,1.479,1.332,3.688,.067,5.199l-24.166,28.776c-1.376,1.649-3.828,1.87-5.477,.493-.015-.012-.029-.025-.044-.037-1.65-1.424-1.851-3.908-.451-5.578l21.957-26.149L16.995,6.594Z"
                  />
                </svg>
                </a>
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--Other Tab Options-->
      <ul class="">
        <li class="">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
               fill="#00789E"
               class="icon-24 inline-flex mb-12">
            <path
              d="M63.27,15.794c-.696-.981-1.824-1.564-3.027-1.563H17.087l-1.933-5.343v-.085c-.05-.126-.107-.25-.171-.369,0-.099-.099-.199-.156-.298s-.142-.199-.227-.298-.142-.185-.227-.27l-.27-.242-.27-.227-.327-.199-.284-.156-.355-.128-.327-.099H3.716C1.664,6.514,0,8.178,0,10.23s1.664,3.716,3.716,3.716h5.343l9.762,26.999c.552,1.448,1.946,2.4,3.496,2.387h30.352c1.586-.009,2.991-1.025,3.496-2.529l7.645-21.628c.382-1.148,.181-2.41-.54-3.382ZM22.203,46.515c3.029,0,5.485,2.456,5.485,5.485s-2.456,5.485-5.485,5.485-5.485-2.456-5.485-5.485,2.456-5.485,5.485-5.485Zm29.884,0c3.029,0,5.485,2.456,5.485,5.485s-2.456,5.485-5.485,5.485-5.485-2.456-5.485-5.485,2.456-5.485,5.485-5.485Z"
            />
          </svg>
          <a class="font-bold ml-16 mt-16 inline-flex" href="/shopping-cart">Cart
             <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            fill="currentColor"
            class="icon-14 inline-flex relative left-1/2 ml-144"
          >
            <path
              d="M16.995,6.594c-1.44-1.634-1.303-4.12,.308-5.586,1.59-1.442,4.049-1.323,5.491,.268,.013,.015,.026,.029,.039,.044l24.169,27.308c1.304,1.479,1.332,3.688,.067,5.199l-24.166,28.776c-1.376,1.649-3.828,1.87-5.477,.493-.015-.012-.029-.025-.044-.037-1.65-1.424-1.851-3.908-.451-5.578l21.957-26.149L16.995,6.594Z"
            />
          </svg>
          </a>
         
        </li>
        <li class="mb-0">
          <img
            src="assets/icons/individual-base.svg"
            alt="shield"
            class="icon-24 inline-flex mb-12"
          />
          <a class="font-bold ml-12 inline-flex" href="/sign-in">Sign In
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            fill="currentColor"
            class="icon-14 inline-flex relative left-1/2 ml-128"
          >
            <path
              d="M16.995,6.594c-1.44-1.634-1.303-4.12,.308-5.586,1.59-1.442,4.049-1.323,5.491,.268,.013,.015,.026,.029,.039,.044l24.169,27.308c1.304,1.479,1.332,3.688,.067,5.199l-24.166,28.776c-1.376,1.649-3.828,1.87-5.477,.493-.015-.012-.029-.025-.044-.037-1.65-1.424-1.851-3.908-.451-5.578l21.957-26.149L16.995,6.594Z"
            />
          </svg> 
          </a>
         
        </li>
      </ul>
    </div>
  </ng-template>

  <main class="flex-1  mt-36">
    <router-outlet></router-outlet>
  </main>
  <app-disclaimer></app-disclaimer>

  <!-- isInternal=true hides the company slogan -->
  <eds-site-footer class="" [isInternal]="true"></eds-site-footer>
</div>

<de-loader *ngIf="isLoading" [showOverlay]="true"></de-loader>

<eds-toast
  [type]="toast?.type"
  [message]="toast?.message"
  [showToast]="showToast"
  [durationInMs]="5000"
  [allowDismiss]="true"
  (toastClosed)="showToast = false"
>
</eds-toast>
