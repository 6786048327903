import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EdsSelectOption } from '@electronds/ng-components-duke';
import { SharedModule } from 'src/app/shared/shared.module';
import { ALL_USA_STATES_LIST, zipCodeValidator } from './states-list-data';
import { AddressComponent, PlaceDetailsResponse } from './google-places-api.interface';

declare const google: any; // Declare the global 'google' variable

@Component({
  selector: 'app-address-search',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './address-search.component.html',
  styleUrl: './address-search.component.scss'
})
export class AddressSearchComponent implements AfterViewInit, OnInit {

    autocomplete:any; // google place reference holder
    placesService:any;
    public zipValidator = zipCodeValidator;
    public statesList:Array<EdsSelectOption> = ALL_USA_STATES_LIST;
    public searchPredictions:Array<AutoCompletePrediction> = [];
    public addressForm: FormGroup;
    public selectedStateOption:EdsSelectOption = {id:-1, value: '', displayText:''};
    public selectedPrediction:AutoCompletePrediction;
    @Output() setAddress: EventEmitter<AddressResult> = new EventEmitter();
    @ViewChild('streetAddress', { read: ElementRef }) addressView;
    // controls
    public streetAddressControl = new FormControl('', [Validators.required]);
    public apartmentSuiteControl = new FormControl('', []);
    public cityControl = new FormControl('', [Validators.required]);
    public stateControl = new FormControl('', [Validators.required]);
    public zipControl = new FormControl('', [Validators.required, Validators.pattern(/^\d{5}(?:\d{2})?$/)]);

    constructor(   private _formBuilder: FormBuilder,private _changeDetectorRef: ChangeDetectorRef, ){
    }

    ngOnInit(): void {
      this.buildAddressForm();
  }
   
    ngAfterViewInit() {
      this.initGoogleAutoCompleteAPI()
    }

  get isGoogleAPILoaded():boolean {
    return google != undefined && 
           google.maps != undefined &&
           google.maps.places != undefined &&
           google.maps.places.AutocompleteService  != undefined;
  }

  public stateSelectionChanged(option:EdsSelectOption): void {
    this.selectedStateOption = option
    this.stateControl.setValue(option.value);
    this.notifyParent();
  }

//Building Address Form
public buildAddressForm():void {
  this.addressForm = this._formBuilder.group({
     streetAddress: this.streetAddressControl,
     apartmentSuite: this.apartmentSuiteControl,
     city: this.cityControl,
     zip: this.zipControl
  });

  this.addressForm.statusChanges.subscribe(status => {
    /// state drop down from electron does not support form control
    /// the form is only valid if its controls are valid + we have a state selected
    if (status == 'VALID' && this.selectedStateOption != undefined ) {
      this.notifyParent();
    }
  }); 
}

public populateFieldsWith(response:PlaceDetailsResponse) {

   const numberComponent:AddressComponent = response.address_components.find((component) => component.types.includes('street_number'));
   const streetComponent:AddressComponent = response.address_components.find((component) => component.types.includes('route')); // route == street
   const cityComponent:AddressComponent   = response.address_components.find((component) => component.types.includes('locality'));
   const stateComponent:AddressComponent  = response.address_components.find((component) => component.types.includes('administrative_area_level_1'));
   const zipComponent:AddressComponent    = response.address_components.find((component) => component.types.includes('postal_code'));

    const numberText:string = (numberComponent != undefined) ? numberComponent.long_name : '';
    const streetText:string = (streetComponent != undefined) ? streetComponent.long_name : '';
    const street:string     = (numberText.length > 0) ? `${numberText} ${streetText}`  : streetText;
    
    // prevent event propagation to avoid re-showing the search results
    this.streetAddressControl.setValue(street, { emitEvent: false });

  if(zipComponent != undefined) {
    this.zipControl.setValue(zipComponent.long_name, { emitEvent: false });
  }
 
  if(cityComponent != undefined) {
    this.cityControl.setValue(cityComponent.long_name, { emitEvent: false });
  }

  if (stateComponent !=  undefined) {
    this.selectedStateOption =  this.statesList.find((option) =>  option.value == stateComponent.short_name );
    this.stateControl.setValue(stateComponent.short_name, { emitEvent: false });
  }

  this._changeDetectorRef.detectChanges();
  this.notifyParent();
}

public isAutoFill(): boolean {
  // when the user auto fills a form from the browser provided tools
  // the  css :-webkit-autofill pseudo class is added to the form, here we check for its presence to detect autofill 
  const wrapper = this.addressView.nativeElement;
  const inputElement = wrapper.querySelector('.form-input-field')
  return this.matches(inputElement, ':-webkit-autofill');
}

matches( node, selector ) {
  // READ MORE: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
  const nativeMatches = ( node.matches || node.msMatchesSelector );
  try {
    return( nativeMatches.call( node, selector ) );
  } catch ( error ) {
    // In the case of an error, we're going to assume it's a pseudo-selector
    // issue and NOT a general support issue (since we don't care about
    // really old browsers).
    return( false );
  }
}

public searchPrediction(query:string):void {
  if(this.autocomplete == undefined) {
    this.initGoogleAutoCompleteAPI();
    return;
  }

  if(this.isAutoFill()) {
    // Prevent search: User has chosen to use the browser auto-fill feature
    return;
  }

  this.autocomplete.getPlacePredictions({
    input: query,
    componentRestrictions: { country: 'US' },
    fields: ['address_components', 'geometry'],
    types: ['address']},
    (predictions, status) => {
      this.searchPredictions =  (status == 'OK') ? predictions : [];
    }
  );
}

public searchItemSelected(prediction:AutoCompletePrediction) {

  this.selectedPrediction = prediction;
   

    const map = new google.maps.Map(document.createElement('div'));
    this.placesService = new google.maps.places.PlacesService(map)

    const request = {
      placeId: prediction.place_id,
      fields: ['geometry', 'types', 'address_components'],
      sessionToken: new google.maps.places.AutocompleteSessionToken
    };
    this.placesService.getDetails(request,   (results, _) => {
    this.populateFieldsWith(results);
  });

  /// clear the predictions array to hide the results after selection
  this.searchPredictions = [];
}

 // notifies any listeners of the current values on the fields 
 public notifyParent() {
  const results:AddressResult = { street:this.streetAddressControl.value,
                                  apartment:this.apartmentSuiteControl.value,
                                  city:this.cityControl.value,
                                  state:this.stateControl.value,
                                  zipCode:this.zipControl.value};

  this.setAddress.emit(results);
 }


 private initGoogleAutoCompleteAPI() {
  if(typeof google !== 'undefined' && this.autocomplete == undefined) {
    this.autocomplete = new google.maps.places.AutocompleteService();
  }
}
}


export interface StructuredFormatting {
  main_text:string;
  secondary_text:string;
}
export interface AutoCompletePrediction {
  place_id : string;
  description:string;
  structured_formatting:StructuredFormatting;
}

export interface AddressResult {
  street:string;
  apartment?:string;
  city:string;
  state:string;
  zipCode:string;
}