
<div class="w-full">
    <img class="h-auto md:min-w-full" src="{{plan.hero.image}}">
</div>

<div class="flex flex-col items-center bg-gray-lighter p-48">
    <div class="flex flex-col items-center max-w-lg text-center">
        <h2 class="text-2xl md:text-4xl pb-20">{{plan.content.title}}</h2>
        <p [innerHtml]="plan.content.description"> {{plan.content.description}}</p>

        <!-- ng for each badge below -->
        <div class="flex flex-row flex-wrap justify-between">
            <div *ngFor="let badge of plan.content.badges" class="flex flex-col text-left md:text-center min-w-80 w-144 py-20">
                <div class="">
                    <img class="mx-auto rounded-full w-[76px] h-[76px]" [src]="badge?.icon" />
                </div>
                <div class="flex-col">
                    <div class="md:pt-20 text-center">{{badge.name}}</div>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Implement tabs here -->
<div class="text-sm text-center items-center align-middle justify-center pt-36">
    <div>
    <ul class="flex flex-wrap flex-col md:flex-row -mb-px items-center text-center justify-center min-w-full text-teal-darker text-lg">
        <li class="me-2 hover:bg-teal">
            <span class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 hover:cursor-pointer" [ngClass]="{'underline': this.whichTab == 0}" (click)="selectTab(0)">Features</span>
        </li> 
        <li class="me-2 hover:bg-teal">
            <span class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 hover:cursor-pointer" [ngClass]="{'underline': this.whichTab == 1}" (click)="selectTab(1)">{{displayHowItWorks ? 'How It Works' : 'Services'}}</span>
        </li>
        <li class="me-2 hover:bg-teal">
            <span class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 hover:cursor-pointer" [ngClass]="{'underline': this.whichTab == 2}" (click)="selectTab(2)">Frequently Asked Questions</span>
        </li>
    </ul>
    </div>
</div>




<!-- selected tab 1 -->
<div *ngIf="whichTab == 0" class="flex flex-col items-center bg-gray-lighter p-48 mt-24">
    <div class="flex flex-col items-center max-w-lg">
        <h2 class="text-xl md:text-4xl pb-20 w-full text-blue">{{plan.tabs.features.title}}</h2>
        <p [innerHTML]="plan.tabs.features.description"></p>

        <!-- looping through the small items -->
        <div *ngFor="let item of plan.tabs.features.items" class="py-16">
            <p class="text-blue">{{item.name}}</p>
            <p>{{item.content}}</p>
        </div>
    </div>
</div>

<!-- selected tab 2 -->
<div *ngIf="whichTab == 1 && displayHowItWorks" class="flex flex-col items-center bg-gray-lighter p-48 mt-24">
    <div class="flex flex-col items-center max-w-lg text-left">
        <h3 class="text-2xl md:text-4xl pb-20">{{plan.tabs.howItworks.title}}</h3>
        <div *ngFor="let step of plan.tabs.howItworks.steps">
            <p [innerHTML]="step"></p>
            <br>
        </div>
    </div>
</div>

<div *ngIf="whichTab == 1 && !displayHowItWorks" class="flex flex-col items-center bg-gray-lighter p-48 mt-24">
    <div class="flex flex-col items-center max-w-lg text-left">
        <h2 class="text-2xl md:text-4xl pb-20">{{plan.tabs.services.name}}</h2>
        <p [innerHTML]="plan.tabs.services.description"></p>
    </div>
</div>


<!-- selected tab 3 -->
<div *ngIf="whichTab == 2" class="flex flex-col items-center bg-gray-lighter p-48 mt-24">
    <app-faq [faqs]="plan.tabs.faqs"></app-faq> 
</div>

<!-- end of selected tabs -->

<!-- Enroll and Save -->
<div class="flex flex-col items-center p-48">
    <div class="flex flex-col items-center max-w-md text-center">
        <h2 class="text-2xl md:text-4xl pb-20">{{plan.tabs.actionCall.tile}}</h2>
        <div [innerHtml]="plan.tabs.actionCall.description ?? 'Error: action call description not found'"></div>
    </div>
</div>
<!-- <app-disclaimer></app-disclaimer> -->
