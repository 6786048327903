export enum UnspportedReasonEnum {
    'non-res',
    'non-def',
    'already-enrolled',
    'not-eligible',
    'app-offline',
    'api-error',
    'duke-energy',
  }
  
  export type UnsupportedReason = keyof typeof UnspportedReasonEnum;
  export const UNSUPPORTED_REASONS = Object.keys(UnspportedReasonEnum);
  
  export interface UnsupportedDto {
    title: string;
    description?: string;
    actionTitle?: string;
    actionLink?: string;
    restartTitle?: string;
    restartLink?: string;
    isError?: boolean;
  }
  
  export type DictUnsupported = Record<UnsupportedReason, UnsupportedDto>;