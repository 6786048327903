
import { Injectable } from '@angular/core';
import {env} from '../../config/env';
import {environment} from '../../environments/environment'
// import {environments} from '../../config/env.config';
@Injectable()
export class AppConfig {
  currentEnvironment = env.env;
  // environmentConfigs = environments;
  private _config: any;
  constructor() {
  }
  /*
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this._config[key];
  }

  public setConfig(key: any, value: any) {
    this._config[key] = value;
    return this._config[key];
  }

  public load() {
    this._config = environment;
  }
}
