<div class="mt-48 md:p-12 text-center items-center justify-center">
  <div class="max-w-md m-auto">
    <div class="flex justify-center w-full pt-20">
      <img src="/assets/icons/informational.svg" alt="Back Arrow" class="icon-small w-48 h-48 pb-12">
    </div>
    <div class="px-8 md:px-40 pb-12" [innerHtml]="disclaimer ?? 'Error: disclaimer not found'"></div>

    <eds-button *ngIf="!isShowingMore" class="mt-10" [isUnderlined]="true" text="Show More" type="tertiary" size="lg" extraTwClasses="px-20 text-md"
      (click)="flipIsShowingMore()">
    </eds-button>

    <div *ngIf="isShowingMore" class="px-8 md:px-40 pb-12" [innerHtml]="extendedDisclaimer ?? 'Error: extendedDisclaimer not found'"></div>

    <eds-button *ngIf="isShowingMore" class="mt-10" [isUnderlined]="true" text="Show Less" type="tertiary" size="lg" extraTwClasses="px-20 text-md"
      (click)="flipIsShowingMore()">
    </eds-button>
</div>