import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EdsSelectOption } from '@electronds/ng-components-duke';

export const ALL_USA_STATES_LIST:Array<EdsSelectOption> = [
    {
        id: 1,
        displayText: 'Alabama',
        value: 'AL'
    },
    {
        id: 2,
        displayText: 'Alaska',
        value: 'AK'
    },
    {
        id: 3,
        displayText: 'Arizona',
        value: 'AZ'
    },
    {
        id: 4,
        displayText: 'Arkansas',
        value: 'AR'
    },
    {
        id: 5,
        displayText: 'California',
        value: 'CA'
    },
    {
        id: 6,
        displayText: 'Colorado',
        value: 'CO'
    },
    {
       id: 7,
        displayText: 'Connecticut',
        value: 'CT'
    },
    {
       id: 8,
        displayText: 'Delaware',
        value: 'DE'
    },
    {
        id: 9,
        displayText: 'Florida',
        value: 'FL'
    },
    {
       id: 10,
        displayText: 'Georgia',
        value: 'GA'
    },
    {
        id: 11,
        displayText: 'Hawaii',
        value: 'HI'
    },
    {
       id: 12,
        displayText: 'Idaho',
        value: 'ID'
    },
    {
        id: 13,
        displayText: 'Illinois',
        value: 'IL'
    },
    {
        id: 14,
        displayText: 'Indiana',
        value: 'IN'
    },
    {
        id: 15,
        displayText: 'Iowa',
        value: 'IA'
    },
    {
        id: 16,
        displayText: 'Kansas',
        value: 'KS'
    },
    {
        id: 17,
        displayText: 'Kentucky',
        value: 'KY'
    },
    {
        id: 18,
        displayText: 'Louisiana',
        value: 'LA'
    },
    {
        id: 19,
        displayText: 'Maine',
        value: 'ME'
    },
    {
        id: 20,
        displayText: 'Maryland',
        value: 'MD'
    },
    {
        id: 21,
        displayText: 'Massachusetts',
        value: 'MA'
    },
    {
        id: 22,
        displayText: 'Michigan',
        value: 'MI'
    },
    {
        id: 23,
        displayText: 'Minnesota',
        value: 'MN'
    },
    {
        id: 24,
        displayText: 'Mississippi',
        value: 'MS'
    },
    {
        id: 25,
        displayText: 'Missouri',
        value: 'MO'
    },
    {
       id: 26,
        displayText: 'Montana',
        value: 'MT'
    },
    {
        id: 27,
        displayText: 'Nebraska',
        value: 'NE'
    },
    {
        id: 28,
        displayText: 'Nevada',
        value: 'NV'
    },
    {
        id: 29,
        displayText: 'New Hampshire',
        value: 'NH'
    },
    {
        id: 30,
        displayText: 'New Jersey',
        value: 'NJ'
    },
    {
        id: 31,
        displayText: 'New Mexico',
        value: 'NM'
    },
    {
        id: 32,
        displayText: 'New York',
        value: 'NY'
    },
    {
        id:33,
        displayText: 'North Carolina',
        value: 'NC'
    },
    {
        id: 34,
        displayText: 'North Dakota',
        value: 'ND'
    },
    {
        id: 35,
        displayText: 'Ohio',
        value: 'OH'
    },
    {
        id: 36,
        displayText: 'Oklahoma',
        value: 'OK'
    },
    {
        id: 37,
        displayText: 'Oregon',
        value: 'OR'
    },
    {
        id: 38,
        displayText: 'Pennsylvania',
        value: 'PA'
    },
    {
        id: 39,
        displayText: 'Rhode Island',
        value: 'RI'
    },
    {
        id: 40,
        displayText: 'South Carolina',
        value: 'SC'
    },
    {
        id: 41,
        displayText: 'South Dakota',
        value: 'SD'
    },
    {
        id: 42,
        displayText: 'Tennessee',
        value: 'TN'
    },
    {
        id: 43,
        displayText: 'Texas',
        value: 'TX'
    },
    {
        id: 44,
        displayText: 'Utah',
        value: 'UT'
    },
    {
        id: 45,
        displayText: 'Vermont',
        value: 'VT'
    },
    {
        id: 46,
        displayText: 'Virginia',
        value: 'VA'
    },
    {
        id: 47,
        displayText: 'Washington',
        value: 'WA'
    },
    {
        id: 48,
        displayText: 'West Virginia',
        value: 'WV'
    },
    {
        id: 49,
        displayText: 'Wisconsin',
        value: 'WI'
    },
    {
        id: 50,
        displayText: 'Wyoming',
        value: 'WY'
    }
];

export function zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      // Check if the value is a string and matches the length of a US zip code (5 digits)
      if (typeof value === 'string' && /^\d{5}$/.test(value)) {
        return null; // Valid zip code
      } else {
        return { invalidZipCode: true }; // Invalid zip code
      }
    };
  }