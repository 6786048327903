export const environment = {
  production: false,
  envName: 'dev',
  apiBaseUrl: 'https://api.one-dev.duke-energy.app/',
  tokenEndpoint: 'https://api.one-dev.duke-energy.app/',
  accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJlY29uaWMtYmFja2VuZC1kZXYiLCJuYW1lIjoiZWNvbmljLWZyb250ZW5kLWRldiJ9.N4ao1ryIbyNGR4KpZxIvj2cy1GnOLs0VQzonY_y7DCU',
  clientKey: '---',
  clientSecret: '---',
  azureB2CInfo: {
    authority: 'https://dukeenergytestb2c.b2clogin.com/dukeenergytestb2c.onmicrosoft.com/',
    tenantId: '56f1d127-82a7-4fb4-80fd-84ce35a5abdf',
    tenantAuthority: 'dukeenergyb2c.b2clogin.com',
    clientId: 'bbfa4535-a024-4a63-b193-77a52b7c1ebd',
    scopes: ['bbfa4535-a024-4a63-b193-77a52b7c1ebd', 'openid', 'offline_access', 'User.Read.All']
  }
};
