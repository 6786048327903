import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, take } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { Customer } from 'src/app/shared/models/customer';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss'
})

export class SignInComponent  implements OnInit, OnDestroy, AfterViewInit {
  isFormValid: boolean;
  passwordValue: string;
  emailValue: string;
  customer: Customer;
  protected _onDestroy = new Subject<void>();


  constructor(
    private _toastService: ToastService,
              private _router: Router,
              private _apiService: ApiService,
              private _googleAnalyticsService: GoogleAnalyticsService,
              private _customerService: CustomerService,
              private _loaderService: LoaderService
            ) { }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
        this.performSignIn();
    }
  }

  ngOnInit(): void {
    this._customerService.customer.pipe(take(1)).subscribe(customer => {
      this.customer = customer;
    });
    this.emailValue = this.customer.email;
  }

  public performSignIn(): void {
    if (this.isFormValid === true) {
      this._loaderService.requestShowLoader();
      this.actualSignIn();
    } else {
      this._loaderService.requestHideLoader();
      this._googleAnalyticsService.sendVPV('sign-in/error/email-or-password-are-incorrect');
      this._toastService.triggerErrorToast('Please check email or password');
    }
  }
  
  actualSignIn(): void {
    this._apiService.signInCustomer(this.emailValue, this.passwordValue).pipe(take(1)).subscribe({
      next: _ => {
        this.handleLoginResponse(this.emailValue);
      },
      error: _ => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Email or password are incorrect');
      },
      complete: () => {
        this._loaderService.requestHideLoader();
      },
    });
  }

  handleLoginResponse(email: string): void {
    // TODO: double check this is working 
    const customer: Customer = new Customer();
    customer.email = email;
    
    this._customerService.updateCustomer(customer);
    this._router.navigate(['home']);
  }

  ngAfterViewInit(): void {
    this._googleAnalyticsService.sendVPV('sign-in/load/sign-in');
  }

  public updateUserEmail($event):void {
    this.emailValue = $event;
  }

  public updateUserPassword($event):void {
    this.passwordValue = $event;
  }

  public updateFormValidation($event): void {
    this.isFormValid = $event;
  }

  public goBack(): void {
    this._googleAnalyticsService.sendVPV('sign-in/load/sign-in');
    this._router.navigate(['checkout/find-email']);
  }

  public goto(url: string): void {
    window.open(url, '_blank');
  }
  
  public register():void {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/register');
    window.open('https://p-auth.duke-energy.com/my-account/registration-r5');
  }

  public needHelpClick() {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/na/need-help-signing-in');
    window.open('https://www.duke-energy.com/need-help-sign-in', '_blank');
  }
  
  public forgotUsernameClick(_) {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/forgot-username');
    window.open('https://www.duke-energy.com/forgot-username', '_blank');
  }

  public forgotPasswordClick(_) {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/forgot-password');
    window.open('https://p-auth.duke-energy.com/my-account/sign-in/forgot-password', '_blank');
  }

  // TODO: still in need of? 
  public eventClick(event) {
    if((event.target.innerHTML).indexOf('help') >= 0) {
      //  this._googleAnalyticsService.sendVPV('sign-in/clickthrough/na/need-help-signing-in');
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
