import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpClient } from '@angular/common/http';
import { catchError, from, Observable, of, take, throwError } from 'rxjs';
import { AppConfig } from '../config/app.config';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { LoaderService } from './loader.service';
import { ToastService } from './toast.service';
import { finalize, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  accessToken = '';
  private _refreshTokenInProgress = false;

  constructor(
    private appConfig: AppConfig,
    private _apiService: ApiService,
    private _loaderService: LoaderService,
    private _toastService: ToastService,
    private router: Router) {
    this.accessToken = this.appConfig.getConfig('accessToken');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // TODO: remove console logs after done testing

    console.log(request.url)

    const authReq = request.clone({ setHeaders: { Authorization: 'Bearer ' + this.accessToken } });

    return next.handle(authReq).pipe(
      catchError((response: HttpResponse<any>) => {
        console.log('error caught')
        if (response && (response.status === 401 || response.status === 0)) {
          console.log('401 or 0 caught')
          if (this._refreshTokenInProgress) {
            console.log('refresh in token in progress - go ahead')
            // If _refreshTokenInProgress is true, we will wait until token is silently refreshed
            // which means the new token is ready and we can retry the request
            return next.handle(authReq)
          } else {
            console.log('refresh not in progress - get the new token')
            this._refreshTokenInProgress = true;

            return from(this._apiService.getNewToken()).pipe(
              switchMap(() => next.handle(this.getAuthRequest(authReq))),
              catchError(error => {
                return throwError(error);
              }),
              // When the call to silentRefresh completes we reset the _refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => this._refreshTokenInProgress = false)
            );
          }
        }

        return throwError(response);
      })
    );
  }

  private getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + this._apiService.getNewToken()
      }
    });
  }
}