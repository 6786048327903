import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  public sendGoogleAnalytics(data: any): void {
    const dataLayer = window['dataLayer'] || [];
    if (dataLayer) {
      dataLayer.push(data);
    }
  }

  public sendEventClick(eventAction: string, eventLabel: string): void {
    this.sendGoogleAnalytics({
      'event-category': 'rate_enrollment',
      'event-action': eventAction,
      'event-label': eventLabel,
      event: 'event-click',
    });
  }

  public sendVPV(vpvName: string): void {
    this.sendGoogleAnalytics({ event: 'send-VPV', 'vpv-name': '/vpv/econic/page/' + vpvName.toLowerCase() });
  }
  
//   TODO: redundant?
//   public actionSendVPV(vpvName: string): void {
//     this.sendGoogleAnalytics({ event: 'send-VPV', 'vpv-name': '/vpv/rate-enroll/' + vpvName.toLowerCase() });
//   }

//   Todo: do we still need this?
//   public transformAndShortenTextForGA(text: string) {
//     let fixedText = text.split('_').join('-').split(' ').join('-');
//     return fixedText.toLowerCase();
//   }
}
