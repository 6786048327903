import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { LocalAWSCartRequest } from 'src/app/Models/cart.models';
import { DataSource } from 'src/app/Models/DataSource';
import { FAQ, HomeContent } from 'src/app/Models/HomeContent';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy {
  public homeContent: HomeContent = DataSource.homeContent();
  public faqs: FAQ[] = [];

  protected _onDestroy = new Subject<void>();

  constructor(
    private _router: Router,
    private _customerService: CustomerService,
    private _apiService: ApiService,
  ) {

  }

  ngOnInit(): void {
    this.faqs = this.homeContent.faqs
    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(_ => {
    });

   
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  clickOnPlan(id: string): void {
    this._router.navigate(['tier'], { queryParams: { plan: id } });
  }
}
