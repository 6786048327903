<div class="container-4xl sm:mx-auto">
    <ul class="divide-y divide-gray">
      <li *ngFor="let item of items; let i = index">
        <div>
          <button
            class="flex items-center justify-between w-full p-16 md:py-24 text-xl text-teal-darker text-left hover:bg-teal-lighter transition-colors duration-200"
            type="button"
            (click)="toggleExpansion(i)"
            (keypress)="toggleExpansion(i)"
            [attr.aria-expanded]="isExpanded(i)"
            [attr.aria-controls]="'accordion-content-' + (i + 1)"
            [id]="'accordion-question-' + (i + 1)"
          >
            {{ item.title }}
  
            <svg class="icon-16 md:icon-20 flex-shrink-0 ml-24 md:ml-32 transform duration-300"
            [ngClass]="{'scale-y-flip': isExpanded(i), '': !isExpanded(i)}"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            fill="currentColor"
            aria-hidden="true"
            focusable="false">
         <path
               d="M57.404,16.997c1.634-1.441,4.123-1.304,5.588,.308,1.442,1.59,1.323,4.048-.268,5.491-.015,.013-.029,.026-.044,.039l-27.307,24.169c-1.479,1.304-3.688,1.332-5.199,.067L1.397,22.905c-1.649-1.376-1.869-3.828-.493-5.476,.012-.015,.025-.029,.037-.044,1.424-1.65,3.908-1.851,5.578-.451l26.148,21.956,24.737-21.893Z" />
       </svg>
          </button>
        </div>
        <div
          class="grid relative px-16 transition-all duration-300"
          [ngClass]="{ 'grid-rows-1fr': isExpanded(i), 'grid-rows-0fr': !isExpanded(i) }"
          [id]="'accordion-content-' + (i + 1)"
          [attr.aria-hidden]="!isExpanded(i)"
          [attr.aria-labelledby]="'accordion-question-' + (i + 1)"
        >
          <div
            [ngClass]="{ 'opacity-100 translate-y-0': isExpanded(i), 'opacity-0 -translate-y-12': !isExpanded(i) }"
            class="overflow-hidden transform transition-all duration-500 text-start"
          >
            <p [innerHtml]="item.content" class="mb-28"></p>
          </div>
        </div>
      </li>
    </ul>
  </div>