import { Component, OnDestroy, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSource } from 'src/app/Models/DataSource';
import { HomeContent, PlanTile } from 'src/app/Models/HomeContent';
import { Plan, PlanContent, Tier } from 'src/app/Models/PlansContent';
import { SharedModule } from 'src/app/shared/shared.module';
import { Customer } from 'src/app/shared/models/customer';
import { ToastService } from 'src/app/services/toast.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-shopping-cart',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './shopping-cart.component.html',
  styleUrl: './shopping-cart.component.scss'
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  public homeContent: HomeContent = DataSource.homeContent();
  public plansContent: PlanContent = DataSource.plansContent();
  plan: Plan;
  
  public selectedItem = -1;
  public tiers: Tier[] = [];
  public customer: Customer;

  public items: PlanTile[] = [];
  protected _onDestroy = new Subject<void>();

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _toastService: ToastService,
    private _customerService: CustomerService
  ) {

  }

  public get subTotal(): number {
    return this.tiers.map((tier) => Number(tier.cost)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
 

  public get monthlyCost(): number {
    return this.subTotal;
   }
  

  ngOnInit(): void {
    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
      this.customer = cust;
    

      // TODO: change the concat to something better
      this.tiers = [];
      this.plansContent.plans.forEach(item => {
        this.tiers = this.tiers.concat(item.content.tiers.filter(plan => this.customer?.addedPlans?.includes(plan.id) ))
      });
    });
  
  }


  remove(id: string) {
   this._customerService.removePlan(id);
  }
 
 
  changePlan(): void {
   this._router.navigate(['/home']);
  }


  continueEnrollment(): void {
    if(this.tiers.length < 1){
      this._toastService.triggerErrorToast('Please select a plan to move forward');
    }
    else {
    this._router.navigate(['checkout/find-email']); 
    }
   
  }


  updateTermsAccepted(): void {
    // this.isTCAccepted = !this.isTCAccepted;
  }

  onClickTCLink() {
    const url =
      'https://www.duke-energy.com/-/media/pdfs/unindexed/231873-8-terms-and-conditions-my-energy-bill.pdf?rev=010eacb1babb47239de6ca832db6ce4d';
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
