<div class="flex flex-col items-center text-center" *ngIf="content">
    <div class="max-w-xl rounded-md sm:mt-48 p-12 sm:py-36 sm:px-96">
        <img class="h-48 w-48 m-auto" [src]="content?.icon">
        <div class="text-2xl mt-12" [class]="content?.extraClasses">
            {{ content.title }}
        </div>
        <div class="text-lg mt-24 mb-64">
            {{ content.description }}
        </div>
        <eds-button
        *ngIf="content.action.length > 0"
        type="{{ content.isPrimary ? 'primary': 'secondary' }}"
        size="2xs"
        (buttonClick)="gotoLink(content.link)"
        extraTwClasses="btn-md btn-full max-w-1/2"
        text="{{content.action}}"></eds-button>
    </div>
</div>