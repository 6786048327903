<div class="relative isolate flex min-h-full gap-16 items-center p-16 bg-white hover:has-[a]:bg-teal-lighter border border-gray rounded-md ring-2 ring-offset-2 ring-transparent has-[:focus-visible]:ring-teal-darker has-[:focus-visible[aria-disabled]]:ring-gray-dark has-[a[aria-disabled]]:opacity-60 has-[a[aria-disabled]]:pointer-events-none has-[a[aria-disabled]]:select-none transition-all ease-in-out duration-300">

    <!-- Leading Icon (optional) -->
    <div class="shrink-0">
        <img class="w-[50px] md:w-[78px]"
        [src]="cardIcon"
        alt="icon" />
    </div>
  
    <!-- Content -->
    <div class="grow">
  
      <!-- Title (required) and Link (optional) -->
      <p class="pt-2 text-lg text-gray-darker">
        <a
          class="text-teal-darker outline-none focus-visible:outline-none before:absolute before:inset-0"
        >
        {{cardTitle}}
        </a>
      </p>
  
      <!-- Description (optional) -->
      <p class="mt-2">
        {{description}}
      </p>
  
    </div>
  
    <!-- Trailing Icon (required if card is linked) -->
    <div class="flex flex-shrink-0 ml-16 items-center">
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 9 14"
             width="20px"
             height="20px">
          <path d="M8.69 6.32l-.237-.22-.02-.02-.016-.01L2.023.278A1.1 1.1 0 0 0 1.285 0a1.1 1.1 0 0 0-.738.277L.31.49a.9.9 0 0 0 0 1.36L5.99 7 .31 12.15a.9.9 0 0 0 0 1.36l.237.213a1.1 1.1 0 0 0 .738.277 1.1 1.1 0 0 0 .738-.277l6.4-5.8.03-.023.237-.214a.9.9 0 0 0 0-1.362z"
                fill="#067799" />
        </svg>
    
      </div>
  
  </div>