export enum OutcomeStatus {
    success = 'success',
    error = 'error'
}

export interface OutcomeContent {
    icon:string;
    extraClasses:string;
    title:string;
    description:string;
    action:string;
    link:string;
    isPrimary:boolean;
}

export function getOutcomeContent(value:OutcomeStatus):OutcomeContent {
    switch (value) {
        case OutcomeStatus.success:
            return {
                icon:'assets/icons/check-solid-green.svg',
                title:'Enrollment Successful!',
                extraClasses:'success',
                description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.',
                action:'',
                link:'',
                isPrimary:true
            }
        case OutcomeStatus.error:
            return {
                icon:'assets/icons/informational.svg',
                title:'Something went wrong',
                extraClasses:'error',
                description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.',
                action:'Previous',
                link:'home',
                isPrimary:false
            }
      }
}
  