<div class="px-24">
  <div class="text-center pb-20">
    <h2
      id="bold"
      class="text-3xl md:text-4xl text-blue md:text-left text-center font-bold"
    >
      Shopping Cart
    </h2>
  </div>
</div>
<!--Selected Plans-->
<div class="md:flex md:gap-32 md:mx-40 mx-10">
  <div class="md:w-2/3 ">
    <div
      class="text-md text-customGreyBold md:text-left text-center font-bold border-2 rounded-t-sm border-gray-lighter bg-gray-lighter p-12"
    >
      1234 Cherry St Columbia SC, 555555
    </div>
    <div
      *ngFor="let plan of tiers"
      class="items-center justify-left pb-12 text-left border-gray-lighter border-[2px] rounded-b-sm md:p-16 p-10 pt-20"
    >
      <div class="md:flex ">
        <img class="icon-80 grid grid-cols-1" src="{{ plan.image }}" alt="plan-icons" />
        <div class="md:flex-col ml-24 text-lg text-customGreyBold font-bold">
          {{ plan.name }}
          <div
            class="font-normal text-lg md:container-sm mt-10 mb-20 text-gray-dark"
          >
            {{ plan.description }}
          </div>
        </div>

        <div class="md:ml-96 flex flex-initial">
          <span class=" font-bold text-customGreyBold">Cost:</span>
          ${{ plan.cost }} per month
        </div>
      </div>

      <div class="flex-col md:ml-80">
        <eds-button
          [isUnderlined]="true"
          type="tertiary"
          text="Change Plan"
          (buttonClick)="changePlan()"
        ></eds-button>
        <eds-button
          [isUnderlined]="true"
          type="tertiary"
          text="Remove"
          (buttonClick)="remove(plan.id)"
        ></eds-button>
      </div>
    </div>
  </div>

  <!--Total Amount-->
  <div class="md:w-2/5 mt-20 md:mt-0">
    <div
      class="text-md text-customGreyBold md:text-left text-center font-bold border-2 rounded-t-sm border-gray-lighter bg-gray-lighter p-12"
    >
      Order Summary
    </div>
    <div class="border-gray-lighter border-2 mb-10">
      <div class="flex p-16">
        <p>Subtotal ({{ tiers.length }} Plan)</p>
        <p class="md:ml-auto ml-144 font-bold">$ {{ subTotal }}</p>
      </div>
      <div class="flex ml-16">
        <p>Taxes</p>
        <img
          class="ml-8 cursor-pointer self-start mt-2"
          src="assets/icons/tooltip-base.svg"
          width="16"
          height="16"
          alt="taxes-tooltip"
        />
        <p class="md:ml-auto ml-64 font-bold">(Calculated at checkout)</p>
      </div>

      <div class="flex border-t-2 border-t-gray-lighter m-12">
        <p class="font-bold m-10">Estimated Monthly Charges</p>
        <p class="pt-2 text-md font-bold md:ml-auto ml-56 mt-8">${{ monthlyCost }}</p>
      </div>
    </div>

    <div>
      <eds-button
        class="pt-24"
        [type]="'primary'"
        [text]="'Checkout'"
        (buttonClick)="continueEnrollment()"
        extraTwClasses="w-full"
      >
      </eds-button>
      <eds-button
        [type]="'tertiary'"
        [text]="'Continue Shopping'"
        isUnderlined="true"
        extraTwClasses="w-full"
      ></eds-button>
    </div>
  </div>
</div>

<!--Recommendation Section-->
<div  class="bg-gray-lighter md:mt-44 md:h-[550px] mt-56">
  <div>
    <h2 class="text-xl md:pt-20 pt-12 md:text-center text-center">We Also Recommend</h2>
  </div>

  <div
    class="md:grid md:grid-cols-2 items-center justify-center gap-24 space-x-4 md:mx-176 mx-40 md:pt-48 pt-20 hover:cursor-pointer"
  >
    <app-simple-card
      class=""
      [cardTitle]="'Gas Line Repair'"
      [description]="'Lorem ispum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna. Ut enim ad minim.'"
      [cardIcon]="'/assets/icons/home-wiring-thumbnail-light.svg'"
    >
    </app-simple-card>

    <app-simple-card
      class=""
      [cardTitle]="'Appliance Repair'"
      [description]="'Lorem ispum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna. Ut enim ad minim.'"
      [cardIcon]="'/assets/icons/home-wiring-thumbnail-light.svg'"
    >
    </app-simple-card>
    <app-simple-card
      class=""
      [cardTitle]="'Heating Repair'"
      [description]="'Lorem ispum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna. Ut enim ad minim.'"
      [cardIcon]="'/assets/icons/water-heater-thumbnail-light.svg'"
    >
    </app-simple-card>
    <app-simple-card
      class=""
      [cardTitle]="'Sewer Line Repair'"
      [description]="'Lorem ispum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna. Ut enim ad minim.'"
      [cardIcon]="'/assets/icons/water-heater-thumbnail-light.svg'"
    >
    </app-simple-card>
  </div>
</div>
