import { AddressResult } from 'src/app/address-search/address-search.component';

export class Customer {
  fullyAuthenticated: boolean;
  email?: string;
  businessPartnerID?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  phone?: Phone;
  address?: AddressResult;
  isResidential?: boolean;
  typeOfProperty?: TypeOfProperty;
  isOwner?: boolean;
  addedPlans: string[] = []

  constructor() {
    this.fullyAuthenticated = false;
    this.email = undefined;
    this.businessPartnerID;
    this.password = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.phone = undefined;
    this.address = undefined;
    this.isResidential = undefined;
    this.typeOfProperty = undefined;
    this.isOwner = undefined;
    this.addedPlans = [];
  }

  public prettyAddress(): string {
    return this.address?.street + ' ' + this.address?.city + ', ' + this.address?.state;
  }
}

export class Phone {
  number: string;
  type: string;

  constructor(number: string = undefined, type: string = '') {
    this.number = number;
    this.type = type;
  }
}

export enum TypeOfProperty {
  singleFamily = 'Single Family',
  multiFamily = 'Multi-family',
  mobileHome = 'Mobile Home'
}