<div class="max-w-lg my-28 mx-auto flex flex-col md:flex-row justify-center">

  <div class="flex-1 flex items-center justify-center h-full clickable" (click)="onClickSignIn()">
    <div class="w-[342px] flex flex-row md:flex-col text-left md:text-center">
      <div class="pr-[20px] md:pr-0">
        <img class="min-w-[70px] h-[70px] md:h-[120px] w-full" [src]="'assets/icons/individual-base.svg'" />
      </div>
      <div class="flex-col">
        <div class="text-xl md:pt-[30px] text-blue">Sign In</div>
        <div class="text-base mt-10 mb-28 text-gray-dark">
          Sign in to your account using your email/username and password
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex flex-col items-center justify-center pb-[60px]">
  <div>
    <eds-button class="mt-10" text="Previous" type="secondary" size="lg" extraTwClasses="py-12 px-20 text-md"
      (click)="returnToDukeEnergy()">
    </eds-button>
  </div>
</div>