import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { UnsupportedReason, UnsupportedDto, DictUnsupported, UNSUPPORTED_REASONS } from 'src/app/Models/Unsupported';

@Component({
  selector: 'app-unsupported',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './unsupported.component.html',
  styleUrl: './unsupported.component.scss'
})

export class UnsupportedComponent implements OnInit {
  readonly DEFAULT_ACTION_TITE = 'View DE 1?';
  readonly DEFAULT_ACTION_LINK = 'https://www.econic.com/';
  readonly DEFAULT_RESTART_TITLE = 'Previous';
  readonly ACCOUNT_SELECTION_LINK = '/questions/account'
  readonly DEFAULT_RESTART_LINK = '/';
  readonly DEFAULT_PREVIOUS_TITLE = 'Previous';

  reason: UnsupportedReason;
  unsupportedDto: UnsupportedDto;
  hasMultipleAccounts: boolean;
  isLoggedIn: boolean;
  showActionButton: boolean;

  private readonly _dict: DictUnsupported = {
    'already-enrolled': {
      title: 'Looks like you are already enrolled in this program.',
      actionTitle: 'Explore Products and Services',
      description:
        'To learn more about additional options available to you please visit the Products and Services page.',
      actionLink: 'https://www.duke-energy.com/info/unindexed/my-energy-bill-plus',
    },
    'api-error': {
      title: "Sorry, we've encountered an error.",
      isError: true
    },
    'app-offline': {
      title: "We're sorry. This offering has expired and is no longer available.",
      description:
        'To learn more about My Energy Bill+, please visit the information page.',
      actionLink: 'https://www.duke-energy.com/info/unindexed/my-energy-bill-plus',
    },
    'non-def': {
      title: "We're sorry. This offering is not available in your area.",
      actionTitle: 'Explore Products and Services',
      description:
        'To learn more about the options available to you please visit the Products and Services page.',
    },
    'non-res': {
      title: "We're sorry. This offering is not available for business accounts.",
      description:
        'To learn more about the options available to you please visit the Products and Services page.',
      actionLink: 'https://www.duke-energy.com/business/products',
    },
    'not-eligible': {
      title: "At this time we don't have any offerings for you.",
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.',
      actionTitle: 'View DE1 ?', 
      actionLink: 'https://www.econic.com/',
      restartTitle: 'Previous',
      restartLink: 'location-check',
      isError: true
    },
    'duke-energy': {
      title: 'This address is serviced by Duke Energy.',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.',
      actionTitle: 'View Products & Services', 
      actionLink: 'https://www.duke-energy.com/home-services',
      restartTitle: 'Previous',
      restartLink: 'location-check',
      isError: false
    },
  };

  constructor (
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private _googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    const reason = ((this.route.snapshot.queryParams['reason'] || '') as string).toLowerCase();
    this.reason = UNSUPPORTED_REASONS.includes(reason) ? reason as UnsupportedReason : 'api-error';
    this.showActionButton = this.reason !== 'app-offline';
    this.unsupportedDto = this._dict[this.reason];
    this._googleAnalyticsService.sendVPV('unsupported-page/load/' + this.reason ?? 'undefined')

  }

  gotoLink(url: string) {
    window.location.href = url;
  }

  gotoPrevious() {
    this.router.navigate([this.unsupportedDto.restartLink ?? this.DEFAULT_RESTART_LINK]);
  }
}
