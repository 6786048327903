import { Injectable } from '@angular/core';
import { EdsToastTypes } from '@electronds/ng-components-duke';
import { Observable, Subject } from 'rxjs';

export type Toast = { type: EdsToastTypes; message: string };

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _toast = new Subject<Toast>();

  get toast(): Observable<Toast> {
    return this._toast.asObservable();
  }

  public triggerInfoToast(message: string): void {
    this._toast.next({ type: 'info', message });
  }

  public triggerSuccessToast(message: string): void {
    this._toast.next({ type: 'success', message });
  }

  public triggerErrorToast(message: string): void {
    this._toast.next({ type: 'error', message });
  }
}
