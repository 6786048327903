import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Detail } from '../Models/PlanDetails';
import { DisclaimerComponent } from '../disclaimer-component/disclaimer.component';
import { FaqComponent } from '../faq/faq.component';
import { FAQ } from '../Models/HomeContent';

@Component({
  selector: 'app-plan',
  standalone: true,
  imports: [DisclaimerComponent, FaqComponent, CommonModule],
  templateUrl: './plan.component.html',
  styleUrl: './plan.component.scss'
})
export class PlanComponent implements OnInit {
  whichTab = 0;
  @Input() plan: Detail;
  questions:FAQ[] = [];
  displayHowItWorks: boolean = false;

  constructor() {}


  ngOnInit(): void {
    this.questions = this.plan.tabs.faqs ?? [];
    // double check this logic below
    this.displayHowItWorks = this.plan.tabs?.howItworks?.title as unknown as boolean ?? false;
  }

  selectTab(whichTab: number): void {
    this.whichTab = whichTab;
  }
}
