import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-accordion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() items!: { title: string; content: string }[];
  @Output() itemClicked = new EventEmitter<{ title: string; isOpen: boolean }>();

  public expandedItems: number[] = [];

  constructor() {}

  public isExpanded(index: number): boolean {
    if (!this.expandedItems) {
      return false;
    }
    return this.expandedItems.includes(index);
  }

  public toggleExpansion(index: number) {
    if (!this.expandedItems) {
      return;
    }
    if (this.expandedItems.includes(index)) {
      this.expandedItems = this.expandedItems.filter(item => item !== index);
    } else {
      this.expandedItems.push(index);
    }

    const item = this.items[index];
    if (item) {
      this.itemClicked.emit({ title: item.title, isOpen: this.isExpanded(index) });
    }
  }
}
