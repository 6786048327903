import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { getOutcomeContent, OutcomeContent, OutcomeStatus } from './enrollment-outcome.content';



@Component({
  selector: 'app-enrollment-outcome',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './enrollment-outcome.component.html',
  styleUrl: './enrollment-outcome.component.scss'
})

export class EnrollmentOutcomeComponent implements OnInit {
  content:OutcomeContent;

  constructor(private route: ActivatedRoute,
         private router: Router,
  ) {}
  ngOnInit(): void {
    const status = this.route.snapshot.paramMap.get('status');
    const outcome:OutcomeStatus = OutcomeStatus[status];
    this.content = getOutcomeContent(outcome);
  }

  
  gotoLink(link:string) {
    this.router.navigate([link]);
  }
}