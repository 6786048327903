<h2 class="text-center text-3xl login-title text-customGrey">Sign In</h2>


  <eds-sign-in
  class="mt-28"
  secondaryText="Previous"
  [showAccountButtons]="false"
  [showRegister]="false"
  (isFormValid)="updateFormValidation($event)"
  (emailValue)="updateUserEmail($event)"
  (passwordValue)="updateUserPassword($event)"
  (signInClick)="performSignIn()"
  (registerClick)="register()"
  (needHelpClick)="needHelpClick()"
  (secondaryClick)="goBack()"
  (click)="eventClick($event)"
>
</eds-sign-in>
