import { EdsSvgComponent } from '@electronds/ng-components-duke';
/* eslint-disable sonarjs/no-all-duplicated-branches */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomSvg } from '../one-header/one-header.component';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';

export interface EdsNavGroup {
  label: string;
  icon: CustomSvg;
  children: EdsNavLink[][];
  expanded?: boolean;
  url?: string
}

export interface EdsNavLink {
  label: string;
  url: string;
}
@Component({
  selector: 'app-tab-dropdown',
  standalone: true,
  imports: [EdsSvgComponent, SharedModule],
  templateUrl: './tab-dropdown.component.html',
  styleUrl: './tab-dropdown.component.scss'
})
export class TabDropdownComponent  {

  @Input() navGroups: EdsNavGroup[] = [];
  @Input() currentNavGroup!: EdsNavGroup;
  @Input() hiddenDetails: string = '';
  @Input() details: string;
  @Output() showDetailsChanged: EventEmitter<boolean> =
  new EventEmitter<boolean>();
  @Input() headerIcon?: CustomSvg;

  constructor(
    private _router: Router,) {

  }

  


  public toggleNavGroup(navGroup: EdsNavGroup): void {
    const wasHiddenBefore = !navGroup.expanded;
    // TODO: Optimized ?
    for (const group of this.navGroups) {
      group.expanded = false;
    }
    if (wasHiddenBefore) {
      navGroup.expanded = true;
    }
  }

  goToPage(url: any): void {
    const blah: string =url.url
    const newUrl = blah.split('=').pop();
    this._router.navigate(['tier'], { queryParams: { plan: newUrl }});
  }

  
}
