import { Component, Input } from '@angular/core';
import { AccordionComponent } from '../accordion/accordion.component';
import { CommonModule } from '@angular/common';
import { FAQ} from '../Models/HomeContent';

interface FaqItem {
    title: string;
    content: string;
}

@Component({
    selector: 'app-faq',
    standalone: true,
    imports: [CommonModule, AccordionComponent],
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
    @Input() faqs: FAQ[] = [];
    get items(): FaqItem[] {  return this.faqs.map(faq => { return { title: faq.question, content: faq.answer } })  }
    constructor() {}
}
