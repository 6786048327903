<nav *ngIf="navGroups.length > 0" class="site-header__nav-bar--internal text-center flex bg-white text-blue"
  role="menubar" aria-label="Navigation Bar">

  <ng-container *ngFor="let navGroup of navGroups">
    <ng-container *ngIf="navGroup.children.length > 0; else navGroupLink">
      <button class="cursor-pointer relative before:absolute before:transition-all before:duration-300" [ngClass]="{
               'before:opacity-100 before:before:top-0 before:left-0 before:right-0 before:-translate-y-4': navGroup.expanded,
               'before:top-0 before:rounded-b-md': !navGroup.expanded,
               'before:opacity-0': !navGroup.expanded && navGroup !== currentNavGroup
             }" role="menuitem" aria-haspopup="tree" [attr.aria-expanded]="navGroup.expanded"
        (click)="toggleNavGroup(navGroup)">
        <div class="nav-group__button inline-flex p-12 transition-all duration-250" [ngClass]="{
          '': !navGroup.expanded,
          'text-blue': navGroup.expanded
          }">
          <span>{{navGroup.label}}</span>
          <div class="transition-transform duration-250" [ngClass]="navGroup.expanded ? '-scale-y-100' : 'scale-y-100'">
            <eds-svg svgPath="components/assets/icons/chevron-down.svg"></eds-svg>
          </div>
        </div>

        <div
          class="nav-group__panel absolute flex cursor-auto shadow-sm text-left py-12 px-24 rounded-b-md transition-all duration-300 bg-white text-blue"
          [ngClass]="[
          navGroup.expanded ? 'visible opacity-100 scale-100 translate-y-0' : 'opacity-0 invisible scale-75 -translate-y-12',
          navGroup.children.length > 1 ? 'left-1/2 -translate-x-1/2' : ''
        ]">
          <ng-container *ngFor="let navLinks of navGroup.children; let i = index">
            <div *ngIf="i === 0" class="nav-group__nav-links flex flex-col w-screen max-w-[220px]">
              <div *ngFor="let navLink of navLinks" (click)="goToPage(navLink)"
                class="block -mx-16 px-16 py-8 hover:bg-teal-lighter hover: cursor-pointer rounded-md focus-visible:-outline-offset-4 transition-colors duration-300 hover:text-blue">
                {{navLink.label}}</div>
            </div>
          </ng-container>
        </div>
      </button>
    </ng-container>
    <ng-template #navGroupLink>
      <a class="nav-group__button cursor-pointer relative block p-12 hover:bg-blue hover:text-white before:absolute before:h-4 before:bg-teal-dark before:transition-all before:duration-300 before:top-0 before:left-12 before:right-12 before:rounded-b-md'"
      [ngClass]="{
               'before:opacity-0': navGroup !== currentNavGroup,
               'before:opacity-100': navGroup === currentNavGroup
             }"
      role="menuitem">
     {{navGroup.label}}
   </a>
    </ng-template>
  </ng-container>
</nav>