<div class="flex flex-col items-center text-center">
    <div class="max-w-xl rounded-md sm:mt-48 p-12 sm:py-36 sm:px-96">
        <svg *ngIf="unsupportedDto.isError === true" class="w-48 h-48 m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76"><path d="M38,0A38,38,0,1,0,76,38,38,38,0,0,0,38,0ZM32.7,19.984a5.984,5.984,0,0,1,11.968,0v.14a5.984,5.984,0,0,1-11.968,0Zm11.979,38.36A4.657,4.657,0,0,1,40.02,63H37.365a4.657,4.657,0,0,1-4.656-4.656V34.689a4.656,4.656,0,0,1,4.656-4.656H40.02a4.656,4.656,0,0,1,4.656,4.656Z" fill="#860000"/></svg>
        <img *ngIf="unsupportedDto.isError !== true" class="h-48 w-48 m-auto" src="assets/icons/informational.svg">

        <div class="text-2xl mt-12  {{unsupportedDto.isError ? 'text-[#860000]' : ''}}">
            {{ unsupportedDto.title }}
        </div>
        <div class=" text-lg mt-24 mb-64">
            {{ unsupportedDto.description }}
        </div>


        <eds-button-group [primaryText]="unsupportedDto.actionTitle ?? DEFAULT_ACTION_TITE" [secondaryText]="unsupportedDto.restartTitle ?? DEFAULT_RESTART_TITLE"
            (primaryClick)="gotoLink(unsupportedDto.actionLink ?? DEFAULT_RESTART_LINK)" (secondaryClick)="gotoPrevious()">
        </eds-button-group>
    </div>
</div>