import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _showLoader = new Subject<boolean>();

  public showLoader(): Observable<boolean> {
    return this._showLoader.asObservable();
  }

  public requestShowLoader(): void {
    this._showLoader.next(true);
  }

  public requestHideLoader(): void {
    this._showLoader.next(false);
  }
}
