
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { Customer } from 'src/app/shared/models/customer';
import { SharedModule } from 'src/app/shared/shared.module';


@Component({
  selector: 'app-transfer',
  standalone: true,
    imports: [SharedModule],
  templateUrl: './transfer.component.html',
  styleUrl: './transfer.component.scss'
})
export class TransferComponent implements OnInit {
 customer: Customer;
 public yesLabel = 'Yes, sync this information with my current Duke Energy profile';
 public noLabel = "No, I'll use a different email address and create a new profile";

 constructor(
  private _router: Router,
  private _apiService: ApiService,
  private _loaderService: LoaderService,
  private _toastService: ToastService,
  private _customerService: CustomerService,
  private _route: ActivatedRoute,
 ){}

 ngOnInit(): void {
  // Get latest customer
  this._customerService.customer.pipe(take(1)).subscribe(customer => {
    this.customer = customer;
  });
}
 public navigateTo():void {
  this._router.navigate(['checkout/find-email']);
 }

 public onTransferAnswerClick(transferAnswer): void {
    this._loaderService.requestShowLoader();
    this.recordTransferProfileResponse(transferAnswer);
 }

 recordTransferProfileResponse(transferAnswer): void {
    this._apiService.recordTransferProfileResponse(this.customer.businessPartnerID, this.customer.email, transferAnswer).pipe(take(1)).subscribe({
      next: resp => {
        console.log(resp);
        this.handleTransferResponse(transferAnswer);
      },
      error: err => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Unknown Error Recording Transfer Profile Response.');
        console.error(err);
      },
      complete: () => {
        this._loaderService.requestHideLoader();
      },
    });
  }

  handleTransferResponse(transferAnswer): void {
    if(transferAnswer === 'YES'){
      this._router.navigate(['sign-in']);
    }
    else{
      this._customerService.updateEmail('');
      this._router.navigate(['checkout/create-profile']);
    }
      
  }
}
