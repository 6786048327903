import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AccordionComponent } from 'src/app/accordion/accordion.component';
import { FaqComponent } from 'src/app/faq/faq.component';
import { DisclaimerComponent } from 'src/app/disclaimer-component/disclaimer.component';
import { PlanComponent } from 'src/app/plan/plan.component';
import { ActivatedRoute } from '@angular/router';
import { FAQ, HomeContent } from 'src/app/Models/HomeContent';
import { DataSource } from 'src/app/Models/DataSource';
import { Detail } from 'src/app/Models/PlanDetails';

@Component({
  selector: 'app-plan-details',
  standalone: true,
  imports: [CommonModule, AccordionComponent, FaqComponent, DisclaimerComponent, PlanComponent],
  templateUrl: './plan-details.component.html',
  styleUrl: './plan-details.component.scss'
})
export class PlanDetailsComponent implements OnInit {
  public homeContent: HomeContent = DataSource.homeContent();
  plan:string;
  questions: FAQ[] = [];
  isLoaded:boolean
  details:Detail;

  constructor(private _route: ActivatedRoute, private _location: Location) {  
      
  }

  ngOnInit(): void {
    this.plan = this._route.snapshot.queryParams.plan;
    this.isLoaded = true;
    this.details = DataSource.getPlanDetailByID(this.plan);
  }
  
  public signIn(): void {
    console.info('you clicked sign in');
  }
}
