import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AddressResult, AddressSearchComponent } from 'src/app/address-search/address-search.component';
import { CustomerService } from 'src/app/services/customer.service';
import { ToastService } from 'src/app/services/toast.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-location-check',
  standalone: true,
  imports: [SharedModule, AddressSearchComponent],
  templateUrl: './location-check.component.html',
  styleUrl: './location-check.component.scss'
})
export class LocationCheckComponent implements OnInit {

  eligForm: FormGroup;
  public residentialControl = new FormControl('residential');
  isResidential = undefined;
  typeOfProperty = undefined;
  isOwner = undefined;
  userAddress: AddressResult = undefined;

  constructor(
    private _router: Router,
    private _customerService: CustomerService,
    private _toastService: ToastService) {
    this.buildForm();
  }

  buildForm(): void {
    this.eligForm = new FormGroup({
      residential: new FormControl(''),
      typeOfProperty: new FormControl(''),
      ownOrRent: new FormControl(''),
    });
  }

  ngOnInit(): void {
    console.log('LocationCheckComponent initialized')
  }

  answerForAddress(address: AddressResult): void {
    this.userAddress = address;
  }

  answerForResidentialQuestion(event: string): void {
    this.isResidential = !event.includes('Non-Res');
  }

  answerForTypeOfPropertyQuestion(event: string): void {
    // TODO: replace with enum 
    if (event.includes('Single')) {
      this.typeOfProperty = 'Single Family'
    } else if (event.includes('Multi')) {
      this.typeOfProperty = 'Multi-family'
    } else if (event.includes('Mobile')) {
      this.typeOfProperty = 'Mobile Home'
    }
  }

  answerForOwnOrRentQuestion(event: string): void {
    this.isOwner = event.includes('Own');
  }

  guardForValidAddress(): boolean {
    return (!this.userAddress || !this.userAddress?.zipCode || !this.userAddress?.city || !this.userAddress?.street || !this.userAddress?.state) ? true : false;
  }

  guardForAllAnswersCompleted(): boolean {
    return (this.isResidential === undefined || this.typeOfProperty === undefined || this.isOwner === undefined) ? true : false;
  }

  public continueActionClicked(): void {
    if (this.guardForValidAddress()) {
      this._toastService.triggerErrorToast('Please enter a valid address.')
    } else if (this.guardForAllAnswersCompleted()) {
      this._toastService.triggerErrorToast('Please answer the questions above.')
    } else {
      this._customerService.updateEligibility(this.userAddress, this.isResidential, this.typeOfProperty, this.isOwner)
      this._router.navigate(['home']);
    }
  }

  public previousActionClicked() {
    this._router.navigate(['home']);
  }

}
