import { Component, Input, OnInit } from '@angular/core';
import { DataSource } from '../Models/DataSource';
import { EdsButtonComponent } from '@electronds/ng-components-duke';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-disclaimer',
  standalone: true,
  imports: [EdsButtonComponent, CommonModule],
  templateUrl: './disclaimer.component.html',
  styleUrl: './disclaimer.component.scss'
})
export class DisclaimerComponent implements OnInit {
  @Input() planId: string;

  disclaimer: string;
  extendedDisclaimer: string;
  isShowingMore: boolean = false;

  constructor() {}

  ngOnInit(): void {
    // Show specific plan disclaimer unless there's not one, then show the basic home disclaimer
    this.disclaimer = this.planId ? DataSource.planContainer(this.planId).disclaimer : DataSource.homeContent().disclaimer;
    this.extendedDisclaimer = this.planId ? DataSource.planContainer(this.planId).extendedDisclaimer : DataSource.homeContent().extendedDisclaimer;
  }

  flipIsShowingMore(): void {
    this.isShowingMore = !this.isShowingMore
  }
}
